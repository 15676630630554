// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("D:\\work\\00_CODE\\praxis-burgweg\\src\\pages\\404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-impressum-js": () => import("D:\\work\\00_CODE\\praxis-burgweg\\src\\pages\\impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("D:\\work\\00_CODE\\praxis-burgweg\\src\\pages\\index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "D:\\work\\00_CODE\\praxis-burgweg\\.cache\\data.json")

